<script lang="ts">import { onMount, onDestroy } from 'svelte';
export let code = '';
export let wrongCode = false;
let className = '';
export { className as class };
const RESEND_TIMEOUT = 300;
let inputDOM = null;
let inputFocused = false;
let resendTimer = null;
let timer = RESEND_TIMEOUT;
onMount(() => {
    if (inputDOM) {
        inputDOM.focus();
    }
});
onDestroy(() => {
    if (resendTimer) {
        window.clearInterval(resendTimer);
    }
});
function handleDigitClick(digitIndex) {
    // User tapped on cell, clear everything after it (including the cell).
    code = code ? code.slice(0, digitIndex) : code;
    if (inputDOM) {
        inputDOM.focus();
    }
}
function handleInput(event) {
    const value = event.target.value;
    if (value.length > 3) {
        code = value.slice(0, 4);
        // User put 4 numbers in, now we can focus out the input.
        if (inputDOM) {
            inputDOM.blur();
        }
        return;
    }
    code = value || '';
}
function handleResend() {
    resendTimer = window.setInterval(() => {
        timer -= 1;
        if (timer === 0) {
            window.clearInterval(resendTimer);
            resendTimer = null;
            timer = RESEND_TIMEOUT;
        }
    }, 1000);
}
function handleFocus() {
    inputFocused = true;
}
function handleBlur() {
    inputFocused = false;
}
</script>

<div class="CodeInput {className}">
  <div>
    <input
      class="CodeInput__input"
      type="number"
      pattern="[0-9]*"
      bind:this={inputDOM}
      bind:value={code}
      on:input={handleInput}
      on:input
      on:focus={handleFocus}
      on:blur={handleBlur}
    />
    <div class="CodeInput__digits">
      {#each [...Array(4).keys()] as number (number)}
        <div
          class="CodeInput__digit"
          class:CodeInput__digit--active={inputFocused && code !== undefined && code !== null && code.length === number}
          class:CodeInput__digit--wrong={wrongCode}
          on:click={() => handleDigitClick(number)}
        >
          {code && code.length > number ? code[number] : ''}
        </div>
      {/each}
    </div>
    {#if wrongCode}
      <div class="CodeInput__error">Неверный код</div>
    {/if}
  </div>
  {#if resendTimer}
    <div class="CodeInput__countdown">Повторно код можно отправить через: {timer} сек</div>
  {:else}
    <div class="CodeInput__resend" on:click={handleResend}>Отправить еще раз</div>
  {/if}
</div>

<style>
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .CodeInput {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .CodeInput__input {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
  }

  .CodeInput__digits {
    display: flex;
  }

  .CodeInput__digit {
    display: flex;
    justify-content: center;
    background-color: var(--silver);
    border-radius: 9px;
    border: 0;
    color: var(--black);
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    line-height: 24px;
    margin: 0 6px;
    padding: 12px 19px;
    width: 48px;
  }

  .CodeInput__digit--wrong {
    margin-top: -2px;
    border: 2px solid var(--danger);
  }

  .CodeInput__digit--active {
    background-color: #f3f9fa;
  }

  .CodeInput__digit--active::after {
    animation: blink 0.75s step-end infinite;
    content: '';
    width: 1px;
    height: 20px;
  }

  .CodeInput__digit:first-child {
    margin-left: 0;
  }

  .CodeInput__digit:last-child {
    margin-right: 0;
  }

  .CodeInput__error {
    color: var(--danger);
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
  }

  .CodeInput__resend {
    color: var(--mainColor);
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin: 24px 0 16px 0;
  }

  .CodeInput__countdown {
    font-size: 14px;
    line-height: 20px;
    margin: 24px 0 16px 0;
    text-align: center;
  }

  @keyframes blink {
    from,
    to {
      background-color: var(--mainColor);
    }

    50% {
      background-color: transparent;
    }
  }
</style>
