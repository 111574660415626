<script lang="ts">let className = '';
export let disabled = false;
export let href = '';
export { className as class };
</script>

{#if href}
  <a {href} target="_blank" rel="noopener noreferrer">
    <button class="Button {className}" class:Button--disabled={disabled} {disabled} on:click><slot
      /></button>
  </a>
{:else}
  <button class="Button {className}" class:Button--disabled={disabled} {disabled} on:click><slot
    /></button>
{/if}

<style>
  a {
    width: 100%;
  }

  .Button {
    background-color: var(--mainColor);
    border-radius: 9px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 16px 0;
    padding: 12px 16px;
    width: 100%;
  }

  .Button--disabled {
    cursor: default;
    opacity: 0.5;
  }
</style>
