<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createEventDispatcher } from 'svelte';
import { staticsStore } from '../../store';
import api from '../../api';
import Button from '../common/Button/Button.svelte';
import CodeInput from '../common/CodeInput/CodeInput.svelte';
import Logo from '../Background/assets/ibolit-logo.svg';
import BackButton from './assets/back.svg';
export let phone = '';
export let name = '';
export let verificationId = '';
const dispatch = createEventDispatcher();
let isFetching = false;
let code = '';
let wrongCode = false;
let statics = null;
staticsStore.subscribe((value) => {
    statics = value;
});
function getParsedNumber(number) {
    return number.replace(/[+()\- ]/g, '');
}
function handleInput() {
    wrongCode = false;
}
function handleSubmit() {
    return __awaiter(this, void 0, void 0, function* () {
        isFetching = true;
        try {
            yield api.register({
                phone: `+${getParsedNumber(phone)}`,
                fullName: name,
                code,
                smsId: verificationId,
            });
            isFetching = false;
            dispatch('success');
        }
        catch (error) {
            isFetching = false;
            if (error.response && error.response.status === 403) {
                wrongCode = true;
            }
            if (error.response &&
                error.response.status === 422 &&
                error.response.data &&
                error.response.data.errors) {
                const errorString = Object.values(error.response.data.errors)[0];
                dispatch('failure', { error: errorString });
                return;
            }
        }
    });
}
</script>

<div class="SmsConfirmation">
  <div class="SmsConfirmation__backArrow" on:click={() => dispatch('back')}>
    <BackButton width="24" height="24" />
  </div>
  <h1>Подтвердите номер</h1>
  <p>
    Для продолжения необходимо ввести код из СМС, отправленного на номер
    <br /><span>{phone}</span>
  </p>
  <h6>Введите код из СМС</h6>
  <CodeInput {wrongCode} bind:code on:input={handleInput} />
  <div class="ClinicWrapper">
    <img
      class="ClinicLogo"
      src={statics && statics.clinic && statics.clinic.avatar}
      alt={statics && statics.clinic && statics.clinic.name}
    />
    <span>{statics && statics.clinic && statics.clinic.name}</span>
  </div>
  <div class="Application">
    <span>Для консультации эксперт приглашает вас в приложение</span>
    <div class="Logo">
      <Logo width="152" height="40" />
    </div>
  </div>
  <Button disabled={!code || code.length < 4 || isFetching} on:click={handleSubmit}>
    Подтвердить
  </Button>
  <span class="Footer">Если у вас возникли трудности со входом в приложение — свяжитесь с
    <a href="mailto:support@yapomogu.pro">support@yapomogu.pro</a>
  </span>
</div>

<style>
  h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }

  h6 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin: 8px 0;
  }

  p {
    color: var(--darkGrey);
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0;
    text-align: center;
  }

  span {
    font-weight: 700;
  }

  .SmsConfirmation {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .SmsConfirmation__backArrow {
    position: absolute;
    top: 4px;
    left: 0;
    cursor: pointer;
  }

  .Application {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    margin: 0 21px 30px;
    background: #fbfbfb;
    border: 1px solid #fdfdfd;
    border-radius: 12px;
    box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
  }

  .Application > span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0 17px;
    color: #858585;
  }

  .Logo {
    padding: 13px 0;
  }

  .ClinicWrapper {
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ClinicWrapper img {
    width: 93px;
    height: 93px;
    border-radius: 8px;
  }

  .ClinicWrapper span {
    margin-top: 8px;
    margin-bottom: 17px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .Footer {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #858585;
    padding-top: 36px;
  }

  /*
   Scoped styles don't work when passed to another component
   https://github.com/sveltejs/svelte/issues/2870
  */
  :global(.SmsConfirmation__icon) {
    margin: 16px 0;
  }
</style>
